import axios from "@/lib/axios";
import socket from "@/lib/socket";
import type { Serialize } from "@dev-team/types";
import type {
	ExchangeRate,
	ExchangeRateRequest,
} from "@dev-team/types/data-provider/models/exchange-rate";
import type { Ticker } from "@dev-team/types/data-provider/models/ticker";
import { defineStore } from "pinia";
import { useAuthStore, usePortfolioStore } from ".";

interface State {
	rateMap: Record<string, Serialize<ExchangeRate>>;
	symbolCurrencyMap: Record<string, string>;
}

export const useCurrencyStore = defineStore("currency", {
	state: (): State => ({
		rateMap: {},
		symbolCurrencyMap: {},
	}),
	getters: {
		rate(state) {
			return (from: string, to: string) => {
				if (!(from && to)) return 1;
				const f = from.includes(".") ? state.symbolCurrencyMap[from] : from;
				const t = to.includes(".") ? state.symbolCurrencyMap[to] : to;
				if (f === t) return 1;
				const rate = state.rateMap[`${f}/${t}`];
				if (!rate) throw new Error(`Subscribe to rate ${from} -> ${to} first`);
				return rate.rate;
			};
		},
		symbolCurrency(state) {
			return (symbol: string) => {
				return state.symbolCurrencyMap[symbol];
			};
		},
	},
	actions: {
		async subscribeRates(rates: ExchangeRateRequest | ExchangeRateRequest[]) {
			if (!Array.isArray(rates)) rates = [rates];
			const notExists = rates
				.filter((r) => !this.rateMap[`${r.from}/${r.to}`])
				// filter out same requests.
				.filter(
					(r, i) =>
						i ===
						(rates as ExchangeRateRequest[]).findIndex(
							(o) => r.time === o.time && r.from === o.from && r.to === o.to,
						),
				);
			if (notExists.length) {
				const { data } = await axios.post<Serialize<ExchangeRate>[]>(
					"/dataprovider/exchange-rate",
					notExists,
				);
				for (const rate of data) {
					const { from, to } = rate;
					const symbol = `${from}/${to}`;
					if (this.rateMap[symbol]) continue;
					socket.emit("join", `PUBLIC:EXCHANGE_RATE:${symbol}`);
					this.rateMap[symbol] = rate;
				}
			}
		},

		async subscribeRatesBySymbols(
			symbols: string[],
			{
				from,
				to,
			}: {
				from?: string;
				to?: string;
			} = {},
		) {
			if (!(from || to)) throw new Error("from or to must be provided");
			const portfolioStore = usePortfolioStore();
			const tickersFromPortfolio = portfolioStore.tickersDetail || [];
			const notExists = symbols.filter(
				(s) =>
					!(
						this.symbolCurrencyMap[s] ||
						tickersFromPortfolio?.map((t) => t.symbol).includes(s)
					),
			);
			let notExistsTickers: Ticker[] = [];
			if (notExists.length) {
				notExistsTickers = await portfolioStore.getTickers(notExists);
			}

			for (const ticker of [...tickersFromPortfolio, ...notExistsTickers]) {
				this.symbolCurrencyMap[ticker.symbol] = ticker.currency;
			}

			const requests: ExchangeRateRequest[] = [];
			for (const symbol of symbols) {
				const currency = this.symbolCurrencyMap[symbol];
				if (!currency) return;

				if (from) requests.push({ from, to: currency });
				if (to) requests.push({ from: currency, to });
			}
			await this.subscribeRates(requests);
		},

		async SOCKET_exchangeRateUpdate({
			from,
			to,
			rate,
			time,
		}: Serialize<ExchangeRate>) {
			const symbol = `${from}/${to}`;
			this.rateMap[symbol] = {
				from,
				to,
				rate,
				time,
			};
		},
	},
});
